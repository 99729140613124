import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import clsx from 'clsx';
import {
  PlusOutlined,
  UsergroupAddOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import SearchCalendar from './Search';
import Timeline from './Timeline';
import {
  GridCol, GridRow, GridShift, useRosterContext,
} from '../Context';
import Shift, { EmptyShift } from './Shift';
import ShiftVariant from './ShiftVariant';
import UnassignedShift from './UnassignedShift';
import { dateFormat } from '../../../../contstant';
import Loading from '../../../Common/Loading';
import EmploymentType, {
  contractedEmployment,
} from '../../../../enums/applicant';

import styles from './index.module.scss';

const getContractedHours = (view: string, hours: number) => {
  switch (view) {
    case 'day':
      return hours / 7;
    case '2 weeks':
      return hours * 2;
    case 'month':
      return hours * 4;
    default:
      return hours;
  }
};

export default function RosterCalendar(): React.ReactNode {
  const {
    view,
    days,
    grid,
    isApplicantPage,
    isDayView,
    gridVariants,
    loading,
    selectedShift,
    loadingVariants,
  } = useRosterContext();
  const navigate = useNavigate();
  const [shadow, setShadow] = useState(true);
  const tableRef = useRef<HTMLTableElement>(null);
  const timelineRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const shadowRef = useRef<HTMLDivElement>(null);

  const onScroll = useCallback(() => {
    if (contentRef.current) {
      setShadow(
        contentRef.current.scrollWidth - contentRef.current.scrollLeft
          > contentRef.current.offsetWidth + 20,
      );

      if (shadowRef.current) {
        shadowRef.current.style.right = `${-contentRef.current.scrollLeft}px`;
        shadowRef.current.style.top = `${contentRef.current.scrollTop}px`;
      }

      if (timelineRef.current) {
        // timelineRef.current.style.left = `${-(contentRef.current.scrollLeft - 211)}px`;
        timelineRef.current.style.left = `${-(
          contentRef.current.scrollLeft - 260
        )}px`;
      }

      if (listRef.current) {
        listRef.current.style.top = `${-(contentRef.current.scrollTop - 60)}px`;
      }
    }
  }, []);

  const reportWindowSize = useCallback(() => {
    if (shadowRef.current && contentRef.current) {
      shadowRef.current.style.right = '0';
    }

    if (contentRef.current) {
      contentRef.current.style.maxHeight = `${
        window.innerHeight - contentRef.current.getBoundingClientRect().top - 81
      }px`;
    }

    if (timelineRef.current && tableRef.current && listRef.current) {
      const time = timelineRef.current.children;
      const list = listRef.current.children;
      const trList = tableRef.current.querySelectorAll('tr');

      if (list && list.length && trList && trList.length) {
        [...trList].forEach((tr, id) => {
          if (list[id]) {
            (list[id] as HTMLDivElement).style.height = `${tr
              .getBoundingClientRect()
              .height.toString()}px`;
          }
        });

        if (trList[0].children) {
          [...trList[0].children].forEach((td, id) => {
            if (time[id]) {
              (time[id] as HTMLDivElement).style.width = `${td
                .getBoundingClientRect()
                .width.toString()}px`;
            }
          });
        }
      }
    }

    onScroll();
  }, []);

  useEffect(() => {
    reportWindowSize();
    setTimeout(reportWindowSize, 100);
  }, [days, grid, gridVariants]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.addEventListener('scroll', onScroll);
    }

    window.addEventListener('resize', reportWindowSize);

    return () => {
      window.removeEventListener('resize', reportWindowSize);
      if (contentRef.current) {
        contentRef.current.removeEventListener('scroll', onScroll);
      }
    };
  }, []);

  const renderShift = (col: GridCol, tdId: number, trId: number) => {
    if (isDayView) {
      const notMultiDay = col.shifts.filter(({ multiDay }) => !multiDay);

      const sortedCol = notMultiDay
        .map((data) => data)
        .sort((a, b) => {
          const aDiff = a.end.diff(a.start, 'seconds');
          const bDiff = b.end.diff(b.start, 'seconds');

          // eslint-disable-next-line no-nested-ternary
          return aDiff === bDiff ? 0 : aDiff > bDiff ? 1 : -1;
        });

      const sortedColStart = sortedCol
        .map((data) => data)
        .sort((a, b) => {
          const aDiff = a.start.unix();
          const bDiff = b.start.unix();

          // eslint-disable-next-line no-nested-ternary
          return aDiff === bDiff ? 0 : aDiff > bDiff ? 1 : -1;
        });

      const result: GridShift[][] = [];

      const recursiveFind = (data: GridShift[]) => {
        let copy = [...data];
        const i = result.length - 1;

        if (copy.length) {
          if (i === -1) {
            result.push([copy[0]]);
            copy.shift();
          } else {
            const lastShift = result[i][result[i].length - 1];

            if (copy[0].start.diff(lastShift.end, 'second') >= 0) {
              result[i].push(copy[0]);
              copy.shift();
            } else {
              const smallShift = copy.find(
                (small) => small.start.diff(lastShift.end, 'second') >= 0,
              );

              copy = copy.filter((small) => small !== smallShift);

              if (smallShift) {
                result[i].push(smallShift);
              } else {
                result.push([copy[0]]);
                copy.shift();
              }
            }
          }

          recursiveFind(copy);
        }
      };

      recursiveFind(sortedColStart);

      return (
        <>
          {col.shifts
            .filter(({ multiDay }) => multiDay)
            .map((data, i) => (
              <Shift
                step={i + 1}
                trId={trId}
                tdId={tdId}
                key={data.id}
                day={col.day}
                data={data}
                unassigned={isApplicantPage && tdId === 0}
                tableWidth={tableRef?.current?.offsetWidth || 0}
              />
            ))}

          {isDayView
           && col.shifts.find(
             (shift) => !shift.beforeStartDay && !shift.beforeEndDay,
           ) ? (
             <EmptyShift size={result.length} />
            ) : null}

          {result.reverse().map((list, i) => (
            <React.Fragment key={list[0].id}>
              {list.map((data) => (
                <Shift
                  step={i + 1}
                  trId={trId}
                  tdId={tdId}
                  key={data.id}
                  day={col.day}
                  data={data}
                  unassigned={isApplicantPage && trId === 0}
                  tableWidth={tableRef?.current?.offsetWidth || 0}
                />
              ))}
            </React.Fragment>
          ))}
        </>
      );
    }

    return col.shifts.map((data, i) => {
      if (data.empty) {
        return <EmptyShift key={data.id} />;
      }

      return (
        <Shift
          step={i + 1}
          trId={trId}
          tdId={tdId}
          key={data.id}
          day={col.day}
          data={data}
          unassigned={isApplicantPage && tdId === 0}
          tableWidth={tableRef?.current?.offsetWidth || 0}
        />
      );
    });
  };

  const renderVariants = (row: GridRow, tdId: number, date: number) => {
    if (!gridVariants?.length) {
      return null;
    }

    if (!isApplicantPage && selectedShift?.locationRole.id !== row.id) {
      return null;
    }

    const variant = isApplicantPage
      ? gridVariants.find((item) => item.id === row.id)
      : gridVariants[0];

    if (!isDayView && variant?.timeSlots?.includes(date)) {
      return <ShiftVariant row={row} date={days[tdId]} />;
    }

    return (
      <div
        className={clsx(styles.variantWrap, {
          [styles.isAppplicant]: isApplicantPage,
        })}
      >
        {variant?.freeDateRanges?.map((slotDate) => (
          <ShiftVariant
            key={slotDate.start}
            start={slotDate.start}
            end={slotDate.end}
            row={row}
            date={days[tdId]}
          />
        ))}
      </div>
    );
  };

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.shadow]: shadow,
        [styles.applicant]: isApplicantPage,
      })}
    >
      {loading && (
        <div className={styles.loading}>
          <Loading size={60} />
        </div>
      )}

      <div className={styles.search}>
        <SearchCalendar />
      </div>

      <div className={styles.timeline} ref={timelineRef}>
        <Timeline />
      </div>

      <div className={styles.list} ref={listRef}>
        {grid?.data.map((row, i) => (isApplicantPage && i === 0 ? (
          <div key={row.totalHours} className={styles.user}>
            <div className={styles.wrp} style={{ paddingTop: '26px' }}>
              <UsergroupAddOutlined />
              <div className={styles.name}>
                Unassigned Shifts
                <div className={styles.time}>
                  {row.totalHours}
                  hrs
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div key={row.id} className={styles.user}>
            <div className={styles.wrp}>
              {isApplicantPage ? <UserSwitchOutlined /> : null}
              <div className={styles.name}>
                {isApplicantPage ? row.user.fullName : row.name}
                <div className={styles.location}>
                  {isApplicantPage
                    ? `${row.employmentType}  ${
                      row.hourlyRate ? ` | $${row.hourlyRate}/hr` : ''
                    }`
                    : `${row.clientName} - ${row.locationName}`}
                </div>

                {row?.rateType === 'no_rate' ? null : (
                  <div className={styles.time}>
                    {!isApplicantPage && `${row.totalHours}hrs`}
                    {isApplicantPage
                      && (contractedEmployment.includes(
                        row.employmentType as EmploymentType,
                      ) ? (
                        <div>
                          {`${row.totalHours} of ${
                            Math.round(
                              getContractedHours(
                                view,
                                row.contractedHoursPerWeek,
                              ) * 100,
                            ) / 100
                          } hrs | `}
                          <span className={styles.remainder}>
                            {Math.round(
                              (Number(row.totalHours)
                               - getContractedHours(
                                 view,
                                 row.contractedHoursPerWeek,
                               ))
                             * 100,
                            ) / 100}
                          </span>
                        </div>
                        ) : (
                          `${row.totalHours} hrs`
                        ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )))}
      </div>

      <div
        className={clsx(styles.content, 'calendar_content')}
        ref={contentRef}
      >
        <table cellPadding={0} cellSpacing={0} ref={tableRef}>
          <tbody>
            {grid?.data.map((row, trId) => (
              <tr
                key={row.id || 'unassigned'}
                className={clsx({
                  [styles.unassigned]: isApplicantPage && trId === 0,
                })}
              >
                {row.days.map((col, tdId) => (
                  <td key={col.day.toISOString()}>
                    <div
                      className={clsx(styles.day, {
                        [styles.padding]: view !== 'day',
                      })}
                    >
                      {!loadingVariants && renderVariants(row, tdId, col.date)}
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {isApplicantPage && trId === 0 ? (
                        col.shifts.length ? (
                          <UnassignedShift col={col} />
                        ) : null
                      ) : (
                        renderShift(col, tdId, trId)
                      )}
                    </div>

                    {!gridVariants?.length ? (
                      <div
                        className={styles.create}
                        onClick={(e) => {
                          e.preventDefault();
                          const applicantData = {
                            date: days[tdId].format(dateFormat),
                          };

                          if (row.id) {
                            // @ts-ignore @typescript-eslint/ban-ts-comment
                            applicantData.applicant = row.id;
                          }

                          const clientData = {
                            date: days[tdId].format(dateFormat),
                            client: row.clientId,
                            domain: row.domainId,
                            locationRole: row.id,
                            location: row.locationId,
                          };
                          const params = new URLSearchParams(
                            isApplicantPage ? applicantData : clientData,
                          ).toString();

                          navigate(`/roster/create?${params}`);
                        }}
                        role="none"
                      >
                        <PlusOutlined />
                      </div>
                    ) : null}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.tableShadow} ref={shadowRef} />
      </div>
    </div>
  );
}
