import { Typography, Space, Tooltip } from 'antd';
import clsx from 'clsx';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

type EarningsProps = {
  earning: string;
  title: string;
  marginBottom?: boolean;
};

function Earnings({ earning, title, marginBottom }: EarningsProps) {
  return (
    <Space direction="vertical" className={clsx(styles.layout, marginBottom ? styles.marginBottom : null)}>
      <Typography.Title level={5}>
        {title}
        <Tooltip
          placement="right"
          title="The projection is accurate if the shift have an hourly rate specified"
        >
          <InfoCircleOutlined
            style={{
              color: 'green',
              marginLeft: 8,
              transform: 'translate(0, 1px)',
              fontSize: 16,
            }}
          />
        </Tooltip>
      </Typography.Title>
      <span className={styles.earning}>
        $
        {earning}
      </span>
    </Space>
  );
}

Earnings.defaultProps = {
  marginBottom: false,
};

export default Earnings;
